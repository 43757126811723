import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const StatusManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "management",
                    "fieldsets": [
                        {
                            "name": "management",
                            "fields": {
                                "status": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "in_configuration",
                                            "value": "in_configuration"
                                        },
                                        {
                                            "label": "in_order",
                                            "value": "in_order"
                                        },
                                        {
                                            "label": "running",
                                            "value": "running"
                                        },
                                        {
                                            "label": "closed",
                                            "value": "closed"
                                        }
                                    ],
                                    "required": true,
                                    "className": "col-12",
                                    "placeholder": "select",
                                    "icon": "faChartNetwork"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'statusManagement', model: dataProviderName, data: data });
        loadOne && await loadOne(item?.data?.id);
        close();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?.id, "status": item?.data?.status }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('Status management')}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Status management')}
                    subtitle={t('Status the plate of {{id}}', { id: item.data.setup ? item.data.setup?.make + " " + item.data.setup?.model : item.data.id })}
                />
            }
            {...params}
        />
    </>
}

export default StatusManagement;


