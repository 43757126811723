import React from "react";
import { useContext } from "react";
import { ActiveModuleName } from '../../../mtska-frontend-app-component';
import { AppContext } from '../../contexts';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();
    const { currentModuleName, currentModuleScope, currentModuleNavigationData } = useContext(AppContext);

    document.title = t(process.env.REACT_APP_PAGETITLE, {cmn: t(currentModuleName)})
    return (
        <header>
            <ActiveModuleName currentModuleName={currentModuleName} currentModuleScope={currentModuleScope} />
            <div className="filler"></div>
        </header>
    );
}

export default Header;
