import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDetailConfigOverrider, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'persons/employment';
const model = 'persons/employment';
const modelExposedName = 'Employment';
const drawerRouteBase = 'employments';


const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();
    const { overrideDetailFields } = useDetailConfigOverrider();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "create_new_employment",
                    "fieldsets": [
                        {
                            "name": "create_new_employment",
                            "fields": {
                                "role": {
                                    "type": "text",
                                    "required": "true",
                                    "className": "col-12"
                                },
                                "status": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "missing_timeframe",
                                            "value": "missing_timeframe"
                                        },
                                        {
                                            "label": "terminated",
                                            "value": "terminated"
                                        },
                                        {
                                            "label": "active",
                                            "value": "active"
                                        },
                                        {
                                            "label": "future",
                                            "value": "future"
                                        }
                                    ],
                                    "readonly": true,
                                    "className": "col-4",
                                    "placeholder": "calculated_by_the_system",
                                    "icon": "faChartNetwork"
                                },
                                "hired_at": {
                                    "type": "date",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "terminated_at": {
                                    "type": "date",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "person": {
                                    "type": "relationshipSingle",
                                    "required": true,
                                    "relation": {
                                        "drawerRoute": "persons"
                                    },
                                    "required": true,
                                    "view": "person"
                                },
                                "legalEntity": {
                                    "type": "relationshipSingle",
                                    "relation": {
                                        "drawerRoute": "legalentities",
                                        "inverseRelation": "person",
                                        "inverseRelationType": "relationship",
                                        "filter": {
                                            "items": [{
                                                "field": "type",
                                                "operator": "equals",
                                                "value": "customer",
                                            }]
                                        },
                                        "limit": 1000
                                    },
                                    "view": "legalEntity",
                                    "required": "true"
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded) {
            loaded = true;
            loadOne(params.filters?._id);
        }
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'create', model: model ? model : dataProviderName, data: data });
        close();
        loadAll();
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;


