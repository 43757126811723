import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const PersonAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "association",
                    "fieldsets": [
                        {
                            "name": "association",
                            "fields": {
                                "person_information": {
                                    "type": "sectiontitle",
                                    "className": "col-12"
                                },
                                "assignment_start_at": {
                                    "type": "date",
                                    "className": "col-6",
                                    "placeholder": "insert"
                                },
                                "assignment_end_at": {
                                    "type": "date",
                                    "className": "col-6",
                                    "placeholder": "insert"
                                },
                                "associated": {
                                    "type": "personlist",
                                    "defaultSelectedValues":[], 
                                    "enableSelection":true, 
                                    "disableMultipleRowSelection":true,
                                    "dataProviderName":"persons/person",
                                    "className": "col-12",
                                    "required": "true"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'personAssociation', model: dataProviderName, data: data });
        loadOne && await loadOne(item?.data?._id);
        close();
    }

    const driver = item.data.pools?.map((pool) => pool.employment?.person)[0];
    
    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('Driver association')}
            alertMessage={(item.data.isPool == 'false' && typeof driver != 'undefined') && t('This operation will terminate the actual assignment to {{firstname}} {{lastname}}', {firstname:driver.firstname, lastname:driver.lastname})}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Associate Driver')}
                    subtitle={t('Select a Driver to be directly associated to {{carIdentifier}}', { carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default PersonAssociation;


