import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'cars/contract';
const drawerRouteBase = 'contracts';

const ServiceAssociation = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "contract_service_association",
                    "fieldsets": [
                        {
                            "name": "contract_service_association",
                            "fields": {
                                "available_services": {
                                    "type": "sectiontitle",
                                    "className":"col-12"
                                },
                                "associated": {
                                    "type": "servicelist",
                                    "defaultSelectedValues":[], 
                                    "enableSelection":true, 
                                    "disableMultipleRowSelection":true,
                                    "dataProviderName":"cars/service",
                                    "filters":{
                                        "items":[
                                            {
                                                "field":"employment_id",
                                                "operator":"isEmpty",
                                                "value":true
                                            },
                                            {
                                                "field":"car_id",
                                                "operator":"isEmpty",
                                                "value":true
                                            },
                                            {
                                                "field":"contract_id",
                                                "operator":"isEmpty",
                                                "value":true
                                            }
                                        ]
                                    },
                                    "className":"col-12",
                                    "required": "true"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'serviceAssociation', model: dataProviderName, data: data });
        loadOne && await loadOne(item?.data?._id);
        close();
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('Service association')}
            header={
                <InfoHeader 
                    view="wizard" 
                    title={t('Associate Service')} 
                    subtitle={t('Assign a Service to {{identifier}}', { identifier: item.data.contractIdentifier })} 
                />
            }
            {...params}
        />
    </>
}

export default ServiceAssociation;


