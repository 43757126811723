import * as Views from "./views";
import { NavigationList } from "../NavigationList"


const Module = {
    name: "contracts",
    defaultRoutePath: "/cars/contract",
    menuItem: {
        scope: "fleet",
        title: "contracts",
        href: "/cars/contract",
        icon: "faFileSignature",
        hidden: true,
    },

    internalMenuItems: {
        activities: NavigationList["fleet"],
        wizards: []
    },
    routes: [
        {
            path: "/cars/contract",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        {
            path: "create",
            Component: Views.Create,
        },
        {
            path: "serviceAssociation",
            Component: Views.ServiceAssociation,
        },
        {
            path: "serviceDissociation",
            Component: Views.ServiceDissociation,
        }
    ],
   
}

export default Module;
