import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';
import { LegalEntityAvatar } from '../Snippet';

import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../utils';

const RelationshipContract = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatDate, formatDistance, formatCurrency } = useFormatter();

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    return v ? (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                <Box className="renter">
                    <LegalEntityAvatar name={v?.renter?.name} url={v?.renter?.logo?.url} withname={true} detail={v?.orderIdentifier ?? t('Missing order identifie')}/>
                </Box>
                <Box className="identifier coupled">
                    <Box className="label">{t('contractIdentifier')}</Box>
                    <Box className="value">{v?.contractIdentifier}</Box>
                </Box>
                <Box className="duration coupled">
                    <Box className="label">{t('contractDuration')}</Box>
                    <Box className="value">{v?.contractDuration} {t('months')}</Box>
                </Box>
                <Box className="mileage coupled">
                    <Box className="label">{t('contractMileage')}</Box>
                    <Box className="value">{formatDistance(v?.contractMileage)}</Box>
                </Box>
                <Box className="start_at coupled">
                    <Box className="label">{t('start_at')}</Box>
                    <Box className="value">{formatDate(v?.start_at)}</Box>
                </Box>
                <Box className="end_at coupled">
                    <Box className="label">{t('end_at')}</Box>
                    <Box className="value">{formatDate(v?.end_at)}</Box>
                </Box>
                <Box className="date coupled norightborder">
                    <Box className="label">{t('totalLeaseFee')}</Box>
                    <Box className="value">{formatCurrency(v?.totalLeaseFee)}</Box>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>

    ) : (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                non assegnato
            </ListItemContent>
        </ListItem>
    )
}


export default RelationshipContract


