import React, { useContext } from 'react';
import { Box, List, ListItem} from '@mui/joy';
import { Anchor } from "../Typography";
import { AppContext } from '../../../mtska-frontend-app-scaffolding';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ModuleMenu = (props) => {
    const { t } = useTranslation();
    const { activities, wizards } = props;
    const location = useLocation();
    const { currentModuleNavigationData } = useContext(AppContext);

    return (
        <nav className="module-menu">
            <List className="module-menu-portion module-menu-portion-activities">
                {Array.isArray(currentModuleNavigationData?.activities) && currentModuleNavigationData.activities.map((activity, k) => {
                    return (
                        <ListItem key={k} className={(activity.href == location.pathname) ? "module-menu-item module-menu-item-selected" : "module-menu-item"}>
                            {/* <Anchor className={(activity.isPreferred) ? 'icon preferred' : 'icon'} icon={'faCircleStar'} iconfamily={'fas'} underline="none">
                                
                            </Anchor> */}
                            <Anchor underline="none" className="label" href={activity.href}>
                                {t(activity.title)}
                            </Anchor>
                        </ListItem>
                    );
                })}
            </List>
        </nav>
    )
};

export default ModuleMenu;
