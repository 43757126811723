import * as Views from "./views";
import { NavigationList } from "../NavigationList"


const Module = {
    name: "bis",
    defaultRoutePath: "/bis/configuration",
    menuItem: {
        scope: "bis",
        title: "BI Configurations",
        href: "/bis/configuration",
        icon: "faChartPieSimpleCircleCurrency",
        hidden: true
    },

    internalMenuItems: {
        activities: NavigationList["bis"],
        wizards: []
    },
    routes: [
        {
            path: "/bis/configuration",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        {
            path: "create",
            Component: Views.Create,
        },
    ]
}



export default Module;
