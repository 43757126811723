import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDetailConfigOverrider, useDrawer, useRelationshipInjector } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'documents/document';
const model = 'documents/document';
const modelExposedName = 'Document';
const drawerRouteBase = 'documents';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();
    const { injectToDetail } = useRelationshipInjector();
    const { overrideDetailFields } = useDetailConfigOverrider();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, true);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        if (params.detailConfigOverride) {
            setDetailConfig(overrideDetailFields(config.data.modules[dataProviderName].detailConfig, params.detailConfigOverride));
        } else {
            setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        }

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded) {
            loaded = true;
            loadOne(params.filters?._id);
        }
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data);
                    loadAll();
                } catch (e) { }
            })()
        }
    }, [success])

    injectToDetail(item?.data, 'ownerModel', params.filters?.modelName);
    injectToDetail(item?.data, 'ownerId', params.filters?._id);
    injectToDetail(item?.data, 'legalEntity', params.filters?.legalEntity);

    // DOCUMENT ****NON USA**** QUESTO PROCESSO DI CREAZIONE TRAMITE JOB
    // const { save: saveJob } = useDataProvider('queues/job');
    // const handleSave = async (data) => {
    //     saveJob && await saveJob({ action: 'create', model: model ? model : dataProviderName, data: data });
    //     close();
    //     loadAll();
    // }

    const handleSave = async (data) => {
        save && await save({ ...data, filters: params.filters });
        //close();
        //loadAll();
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;





