import React, { useState, useEffect } from 'react';
import { Box, ModalClose } from "@mui/joy"
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { Button, DetailTitle, Text } from '../../../../mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';

const Detail = (params) => {

    const { isDrawer, uid, } = params;
    const { t } = useTranslation();

    const [cap, setCap] = useState();
    const { item: config } = useDataProvider('configs/config');
    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const { item, loadOne } = useDataProvider('bis/viewer');

    const [defaultValues, setDefaultValues] = useState({})

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.id)) {
            loadOne(params.id);
        }
        loaded = true;
    }, []);

    useEffect(() => {
        if (!item?.data) return
        setDefaultValues(item.data)
    }, [item])


    return (
        <>
            {isDrawer && (
                <Box className="drawer-section-header" >
                    <ModalClose />

                    <DetailTitle title={defaultValues?.name} />

                    <Box display={"flex"} flexDirection="row"
                        justifyContent={"end"}
                        gap={1}
                        flexGrow={1}>

                        <Button
                            onClick={(e) => {
                                const el = document.getElementById(uid);
                                if (el) el.requestFullscreen();
                            }}
                        >
                            {t("Full screen")}
                        </Button>
                    </Box>

                </Box>
            )}
            <Box padding={0}>
                <div style={{ height: "100vh", width: "100%" }}>

                    <iframe width="100%" height="100%" src={defaultValues?.url_embed}
                        frameborder="0"
                        style={{}}
                        allowfullscreen
                        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                        id={uid}
                    >
                    </iframe>
                </div>
            </Box>
        </>
    )

}

export default Detail;
